<template>
<div>
  <Notification
    @setNotificationDisplay=setNotificationDisplay
    :title="notification.title"
    :message="notification.message"
    :isSuccess="notification.isSuccess"
    :isDisplayed="notification.isDisplayed"
    :duration="notification.duration"
  />
  <div class="min-h-screen bg-blue-200 flex flex-col justify-center pb-12 sm:px-6 lg:px-8">
    <div class="mt-0 sm:mt-10">
      <div class="lg:w-1/2 md:w-3/4 sm:w-4/5 w-5/6 mx-auto mt-5 md:mt-0 md:col-span-2">
        <div class="shadow overflow-hidden rounded-lg">
          <FunnelSteps :currentStep=$route.meta.funnelStep :slug="$route.params.slug" />
          <router-view @showNotification=showNotification></router-view>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import FunnelSteps from "@/components/FunnelSteps"
import Notification from "@/components/ui/Notification"

export default {
  components: {
    FunnelSteps,
    Notification
  },
  data() {
    return {
      isPassengerDataFound: false,
      notification: {
        title: 'Internal server error',
        message: 'Please contact us at hello@upclaim.eu',
        isSuccess: false,
        isDisplayed: false,
        duration: 3000
      },
    };
  },
  async mounted() {
    const { funnelStep } = this.$route.meta
    if (!funnelStep) {
      this.$router.push({ name: "FunnelHomepage" })
    }
  },
  methods: {
    showNotification(notification) {
      this.notification = { ...notification, isDisplayed: true }
    },
    setNotificationDisplay(isDisplayed) {
      this.notification.isDisplayed = isDisplayed
    }
  }
};
</script>

<style>
</style>