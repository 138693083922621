<template>
<div>
  <router-link v-if=isLink
    :to="linkUrl"
    :class="classObject" 
    class="flex items-center px-2 py-2 text-sm font-medium leading-6 rounded-md group"
    aria-current="page"
  >
    <HeroIcons :name="iconName" class="w-6 h-6 mr-4 text-blue-200" />
    <span class="mr-2">{{ text }}</span>
    <slot></slot>
  </router-link>

  <p v-else
    class="flex items-center px-2 py-2 text-sm font-medium leading-6 text-blue-100 rounded-md group"
    aria-current="page"
  >
    <HeroIcons :name="iconName" class="w-6 h-6 mr-4 text-blue-200" />
    <span class="mr-2">{{ text }}</span>
    <slot></slot>
  </p>
</div>
</template>

<script>
import HeroIcons from './ui/HeroIcons';

import { classListToObject } from '@/utils';

export default {
  components: {
    HeroIcons
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    linkUrl: String,
    text: {
      type: String,
      required: true
    },
    iconName: {
      type: String,
      required: true
    },
    isSubItem: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classObject() {
      const activeClass = this.isActive
        ? 'bg-blue-800 text-white'
        : 'text-blue-100 hover:text-white hover:bg-blue-600'


      const allClass = [activeClass]

      return classListToObject(allClass.join(' '))
    },
    isLink() {
      return !!this.linkUrl
    }
  }
}
</script>

<style>

</style>