
import { API } from "interfaces";

import ClaimTimelineElement from './ClaimTimelineElement.vue'

export default {
  components: { ClaimTimelineElement },
  name: 'ClaimTimeline',
  props: {
    claim: Object
  },
  computed: {
    claimEvents() {
      const events: API.ClaimEvent[] = this.claim?.events;

      return events?.sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime())
    }
  }
}
