import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { API } from '../interfaces'
import { claims } from '../utils'

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    isAuthenticated: false,
    profile: {} as API.UserProfile,
    claims: [] as API.ClaimDetail[],
    userLang: ''
  },
  getters: {
    isAuthenticated(state) {
      return !!state.isAuthenticated
    },
    profile(state) {
      return state.profile
    },
    claims(state) {
      return state.claims
    },
  },
  mutations: {
    setAuthenticated(state) {
      state.isAuthenticated = true
    },
    setUnauthenticated(state) {
      state.isAuthenticated = false
    },
    setProfile(state, data) {
      state.profile = {
        ...data,
        // @QUICK-FIX
        fullName: `${data.firstName} ${data.lastName}`,
        profilePictureUrl: `/img/avatar.png`
      }
    },
    setClaims(state, data) {
      state.claims = data
    },
    setUserLanguage(state, userLang) {
      if (userLang) state.userLang = userLang
    }
  },
  strict: debug
});

export default store