function classListToObject(classList: string) {
  const classArray = classList.split(' ')
  const classObject = {} as any

  for (const className of classArray) {
    classObject[className] = true
  }

  return classObject
}

export default classListToObject