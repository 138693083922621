<template>
<div>
  <Notification
    @setNotificationDisplay=setNotificationDisplay
    :title="notification.title"
    :message="notification.message"
    :isSuccess="notification.isSuccess"
    :isDisplayed="notification.isDisplayed"
    :duration="notification.duration"
  />
  <div class="flex flex-col justify-center min-h-screen px-4 bg-gradient-to-b from-blue-100 to-blue-100 sm:px-6 lg:px-8">
    <div class="w-full max-w-md mx-auto">
      <img class="w-auto h-12 mx-auto -mt-24 sm:-mt-12" src="@/assets/logo.svg" alt="Workflow">
      <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
        <!-- Sign in to your account -->
        {{ $t('pages.login.signInTitle') }}
      </h2>
    </div>

    <div class="w-full max-w-md mx-auto mt-8">
      <div class="px-6 py-8 bg-white rounded-lg shadow sm:px-10">
        <form class="space-y-6" action="#" method="POST">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              {{ $t('common.form.emailAddress') }}
            </label>
            <div class="mt-1">
              <input v-model="email" id="email" name="email" type="email" autocomplete="email" required class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              {{ $t('common.form.password') }}
            </label>
            <div class="mt-1">
              <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input id="remember_me" name="remember_me" type="checkbox" class="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500">
              <label for="remember_me" class="block ml-2 text-sm text-gray-900">
                <!-- Remember me -->
                {{ $t('pages.login.rememberMe') }}
              </label>
            </div>

            <div class="text-sm">
              <router-link to="#" class="font-medium text-blue-600 hover:text-blue-500">
                <!-- Forgot your password? -->
                {{ $t('pages.login.resetPassword') }}
              </router-link>
            </div>
          </div>

          <div>
            <button @click="login" :disabled="waitingForApiResponse" type="submit" class="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <div v-if=!waitingForApiResponse>
                <!-- Sign in -->
                {{ $t('pages.login.signInButton') }}
              </div>
              <pulse-loader v-else color="#fff" size="6px" />
            </button>
          </div>
        </form>

        <!-- <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="px-2 text-gray-500 bg-white">
                Or continue with
              </span>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-3 mt-6">
            <div>
              <a href="#" class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50">
                <span class="sr-only">Sign in with Facebook</span>
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                  <path fill-rule="evenodd" d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z" clip-rule="evenodd" />
                </svg>
              </a>
            </div>

            <div>
              <a href="#" class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50">
                <span class="sr-only">Sign in with Twitter</span>
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
            </div>

            <div>
              <a href="#" class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50">
                <span class="sr-only">Sign in with GitHub</span>
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z" clip-rule="evenodd" />
                </svg>
              </a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import { axios, http } from '@/utils'

import Notification from '@/components/ui/Notification.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: 'Login',
  components: {
    Notification,
    PulseLoader
  },
  data() {
    return {
      waitingForApiResponse: false,
      email: '',
      password: '',
      notification: {
        title: '',
        message: '',
        isSuccess: false,
        isDisplayed: false,
        duration: 6000
      }
    }
  },
  methods: {
    async login(e) {
      e.preventDefault()
      try {
        this.waitingForApiResponse = true
        const { data } = await axios.post(`/auth/login`, {
          email: this.email,
          password: this.password
        }, {withCredentials: true})

        const { data: claims } = await http.claims.list()
        this.$store.commit('setClaims', claims)

        this.$store.commit('setProfile', data.profile)
        this.$store.commit('setAuthenticated')
        this.$router.push('/')

        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'User logged in ' + this.email,
          level: Sentry.Severity.Info
        })
        Sentry.setUser({ email: this.email });
      } catch (error) {
        console.log(error)
        this.showNotification({
          title: error.response?.data?.message || error.message,
        })
        throw error;
      } finally {
        this.waitingForApiResponse = false
      }
    },
    showNotification(notification) {
      this.notification = { ...this.notification, ...notification, isDisplayed: true }
    },
    setNotificationDisplay(isDisplayed) {
      this.notification.isDisplayed = isDisplayed
    }
  }
}
</script>

<style>

</style>