
import { API } from "@/interfaces"
import { axios, config } from "@/utils";
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js'

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

function getMonthsForLocale() {
  const SAFARI_OFFSET_FIX = 1;
  const months = [];

  for (let month = 0; month < 12; month++) {
    const localizedMonth = new Date(0, month, 1 + SAFARI_OFFSET_FIX, 0, 0, 0).toLocaleString('default', { month: 'long' })
    months.push(localizedMonth);
  }

  return months;
}

export default {
  components: {
    PulseLoader
  },
  data() {
    return {
      isPassengerDataFound: false,
      waitingForApiResponse: false,
      countries: config.EU_COUNTRIES_LIST,
      failedToFetchData: null,
      localizedMonths: getMonthsForLocale(),
      passenger: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        phoneCountryCode: '',
        birthdate: {
          day: 1,
          month: 1,
          year: 1990
        },
        nationalityCountryCode: '',
        streetAddress: '',
        city: '',
        zipCode: '',
      },
      notification: {
        title: 'Étape validée',
        message: 'Votre identitée a bien été confirmée',
        isSuccess: true,
        isDisplayed: true,
        duration: 4000
      },
    };
  },
  async mounted() {
    try {
      const res = await axios.get(`/passengers/funnel/${this.$route.params.slug}`);
      const funnelData = res.data as API.RetrieveFunnelResponse;
      const { user } = funnelData.claim;
      const { id, ...passenger } = user;

      const phone = parsePhoneNumber(passenger.phone, 'FR')
      const birthdate = new Date(passenger.birthdate)

      this.passenger = {
        ...passenger,
        phone: phone.formatNational(),
        phoneCountryCode: phone.country || 'FR',
        birthdate: {
          day: birthdate.getDate(),
          month: birthdate.getMonth(),
          year: birthdate.getFullYear()
        },
      };
      this.failedToFetchData = false
    } catch (error) {
      this.failedToFetchData = true
      console.log(">>> error", error);
    }
  },
  computed: {
    phoneNumber: {
      get() {
        return this.passenger.phone
      },
      set(newValue) {
        const phone = new AsYouType(this.passenger.phoneCountryCode) as any //@TODO fix type error
        phone.input(newValue)

        if (phone.country !== this.passenger.phoneCountryCode) {
          this.passenger.phoneCountryCode = phone.country
        }

        this.passenger.phone = phone.formattedOutput
      }
    },
    phoneNumberCountryCode: {
      get() {
        return this.passenger.phoneCountryCode
      },
      set(newCountryCode) {
        this.passenger.phoneCountryCode = newCountryCode
        const phone = new AsYouType(newCountryCode) as any //@TODO fix type error
        phone.input(this.passenger.phone)
        const callingCode = phone.getCallingCode()
        if (callingCode) {
          const nationalPhone = phone.getChars().split(callingCode)[1]
          this.passenger.phone = new AsYouType(newCountryCode).input('0' + nationalPhone)
        } else {
          this.passenger.phone = phone.formattedOutput
        }
      }
    }
  },
  methods: {
    async saveIdentity(e) {
      e.preventDefault();
      try {
        const payload = {
          ...this.passenger,
          phone: parsePhoneNumber(this.passenger.phone, this.passenger.phoneCountryCode)?.number,
          birthdate: new Date(
            this.passenger.birthdate.year,
            this.passenger.birthdate.month,
            this.passenger.birthdate.day,
            12 // Prevent UTC diff
          )
        }

        delete payload.email
        delete payload.phoneCountryCode

        const slug = this.$route.params.slug
        this.waitingForApiResponse = true
        await axios.patch(`/passengers/funnel/${slug}/confirm-identity`, payload);

        this.$router.push({ name: "FunnelWarrant", params: this.$route.params })
      } catch (error) {
        this.notification = {
          title: 'Invalid form',
          message: error.response?.data?.message || error.message,
          isSuccess: false,
          duration: 6000
        }
      } finally {
        this.waitingForApiResponse = false
        this.emitNotification()
      }
    },

    emitNotification() {
      this.$emit('showNotification', this.notification)
    }
  }
};
