
import { API } from "@interfaces";
import { http } from '../utils'
import ClaimTimeline from '@/components/ClaimTimeline2.vue'

export default {
  components: {
    ClaimTimeline
  },
  async beforeMount() {
    const { data } = await http.claims.retrieve(this.$route.params.id)
    // @TODO: Update store?
    this.claim = data as API.ClaimDetail;
  },
  data() {
    return {
      claim: null
    }
  },
  created() {
    this.$watch(() => this.$route.params, async (toParams, fromParams) => {
      if (!toParams.id || toParams.id === fromParams.id) {
        return
      }
      const claimId = toParams.id
      const { data } = await http.claims.retrieve(claimId)
      this.claim = data
    })
  },
}
