
import * as Sentry from '@sentry/browser'

import { setupAxiosInterceptors } from '@/utils/axios'



function storeUserLanguage(store) {
  const userLang = navigator.language || navigator["userLanguage"];
  store.commit('setUserLanguage', userLang)
}

export default {
  name: 'Upclaim',
  created() {
    setupAxiosInterceptors()
    storeUserLanguage(this.$store)

    const email = this.$store.getters.profile?.email || null;
    Sentry.startTransaction({ name: "page-load" });
    Sentry.setUser({ email });
  },
  components: {

  },
}
