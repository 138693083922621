import CLAIMS_EVENTS from "./claims-events.json"
import EU_COUNTRIES_LIST from "./eu-countries.json"

const config = {
  API_URL: process.env.VUE_APP_API_URL || 'https://api.upclaim.eu',
  SENTRY_DSN: process.env.SENTRY_DSN || 'https://ead242bdcf1c4cc981bf09698545b417@o816514.ingest.sentry.io/5812801',
  DEFAULT_TRANSITION: 'fade',

  // Constants
  EU_COUNTRIES_LIST,
  CLAIMS_EVENTS,
}

export default Object.freeze(config)