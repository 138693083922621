import { createWebHistory, createRouter } from "vue-router";

import Login from "@/views/Login.vue";
import DashboardLayout from "@/layouts/Dashboard.vue";
import DashboardHomepage from "@/views/Dashboard.vue";
import ClaimDetails from "@/views/ClaimDetails.vue";

// Funnel (In steps order)
import FunnelFormLayout from "@/layouts/Funnel.vue"
import FunnelHomepage from "@/views/funnel/FunnelHomepage.vue";
import ConfirmIdentityForm from "@/views/funnel/ConfirmIdentityForm.vue";
import Warrant from "@/views/funnel/Warrant.vue"
import LegalDocsForm from "@/views/funnel/LegalDocsForm.vue"
import DefinePassword from "@/views/funnel/DefinePassword.vue"

import store from "@/store"

const routes = [
  {
    name: "DashboardLayout",
    path: "/",
    component: DashboardLayout,
    meta: { requiresAuth: true, transitionName: 'slide-right' },
    children: [
      {
        name: "Dashboard",
        path: "/",
        component: DashboardHomepage,
        meta: {
          transitionName: 'fade'
        }
      },
      {
        name: "ClaimDetails",
        path: "/claims/:id",
        component: ClaimDetails,
        meta: {
          title: 'Claim',
          transitionName: 'fade'
        }
      },
    ]
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
    meta: {
      title: 'Bienvenue',
      requiresAuth: false,
      transitionName: 'slide-left'
    }
  },
  {
    name: "FunnelHomepage",
    path: "/eligible/:slug",
    component: FunnelHomepage,
    meta: {
      title: 'Indemnisation',
      requiresAuth: false,
      transitionName: 'slide-left'
    },
  },
  {
    name: "FunnelLayout",
    path: "/eligible/:slug",
    component: FunnelFormLayout,
    meta: {
      requiresAuth: false,
      transitionName: 'slide-right'
    },
    children: [
      {
        name: "FunnelConfirmIdentity",
        path: "/eligible/:slug/confirm-identity",
        component: ConfirmIdentityForm,
        meta: {
          title: 'Confirm Identiy (1/4)',
          transitionName: 'slide-right',
          requiresAuth: false,
          funnelStep: 1,
        }
      },
      {
        name: "FunnelWarrant",
        path: "/eligible/:slug/warrant",
        component: Warrant,
        meta: {
          title: 'E-Signature (2/4)',
          transitionName: 'slide-right',
          requiresAuth: false,
          funnelStep: 2
        }
      },
      {
        name: "FunnelLegalDocs",
        path: "/eligible/:slug/legal-docs",
        component: LegalDocsForm,
        meta: {
          title: 'Legal Docs (3/4)',
          transitionName: 'slide-right',
          requiresAuth: false,
          funnelStep: 3
        }
      },
      {
        name: "FunnelDefinePassword",
        path: "/eligible/:slug/define-password",
        component: DefinePassword,
        meta: {
          title: 'Define Password (4/4)',
          requiresAuth: false,
          funnelStep: 4
        }
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
});

router.beforeEach(async (to, from) => {
  // By default all routes require auth, unless explicitly set otherwise
  if (to.meta.requiresAuth !== false && !store.getters.isAuthenticated) { 
    router.push({ name: 'Login' });
  }

  const fromFunnelStep = from?.meta?.funnelStep as number;
  const toFunnelStep = to.meta.funnelStep as number;
  if (Number.isInteger(toFunnelStep) && Number.isInteger(fromFunnelStep)) {
    to.meta.transitionName = toFunnelStep > fromFunnelStep ? 'slide-right' : 'fade'
  }

  // Define page's title
  const metaTitle = to.meta.title ? `${to.meta.title} - Upclaim` : 'Dashboard - Upclaim'
  document.title = metaTitle
})

export default router;
