<template>
  <div>
    <form @submit="saveIdentity">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-3">
            <label
              for="first_name"
              class="block text-sm font-medium text-gray-700"
              >{{ $t('common.form.firstName') }}</label
            >
            <input
              v-model="passenger.firstName"
              type="text"
              name="first_name"
              id="first_name"
              autocomplete="given-name"
              class="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div class="col-span-3">
            <label
              for="last_name"
              class="block text-sm font-medium text-gray-700"
              >{{ $t('common.form.lastName') }}</label
            >
            <input
              v-model="passenger.lastName"
              type="text"
              name="last_name"
              id="last_name"
              autocomplete="family-name"
              class="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label
              for="email_address"
              class="block text-sm font-medium text-gray-700"
            >
              {{ $t('common.form.emailAddress') }}
            </label>
            <input
              v-model="passenger.email"
              type="text"
              name="email_address"
              id="email_address"
              autocomplete="email"
              class="block w-full mt-1 bg-gray-100 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              disabled
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label
              for="phone"
              class="block text-sm font-medium text-gray-700"
            >
              {{ $t('common.form.phoneNumber') }}
            </label>
            <div class="relative mt-1 rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 flex items-center">
                <label for="phone" class="sr-only">Phone</label>
                <select
                  v-model="phoneNumberCountryCode"
                  id="phone_number_country_code"
                  name="phone_number_country_code"
                  class="h-full py-0 pl-3 text-gray-500 bg-transparent border-transparent rounded-md focus:ring-indigo-500 focus:blue-indigo-500 pr-7 sm:text-sm"
                >
                  <option
                    v-for="country in countries"
                    v-bind:key="country.code"
                    :value="country.code"
                    :selected="
                      country.code === passenger.phoneCountryCode
                    "
                  >
                    {{ country.emoji }} {{ country.code }}
                  </option>
                </select>
              </div>
              <input
                v-model="phoneNumber"
                type="tel"
                name="phone_number"
                id="phone_number"
                class="block w-full pl-24 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-blue-500 sm:text-sm"
                placeholder="+33 6 42 42 42 42"
              />
            </div>
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label
              for="country"
              class="block text-sm font-medium text-gray-700"
            >
              {{ $t('common.form.nationality') }}
            </label>
            <select
              id="country"
              name="country"
              autocomplete="country"
              v-model="passenger.nationalityCountryCode"
              class="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option
                v-for="country in countries"
                v-bind:key="country.code"
                :value="country.code"
                :selected="
                  country.code === passenger.nationalityCountryCode
                "
              >
                {{ country.emoji }} {{ country.name }}
              </option>
            </select>
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label
              for="birth_date"
              class="block text-sm font-medium text-gray-700"
            >
              {{ $t('common.form.birthdate') }}
            </label>
            <div class="relative mt-1 rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 flex items-center">
                <label for="birth_date" class="sr-only">Birth Date</label>
                <select
                  v-model="passenger.birthdate.day"
                  id="birth_date_day"
                  name="birth_date_day"
                  class="h-full py-0 pl-3 text-gray-500 bg-transparent border-transparent rounded-md focus:ring-indigo-500 focus:blue-indigo-500 pr-7 sm:text-sm"
                >
                  <option
                    v-for="day in 31"
                    v-bind:key="day"
                    :value="day"
                    :selected="day === passenger.birthdate.day"
                  >
                    {{ day }}
                  </option>
                </select>
                <select
                  v-model="passenger.birthdate.month"
                  id="birth_date_month"
                  name="birth_date_month"
                  class="h-full py-0 text-gray-500 bg-transparent border-transparent rounded-md focus:ring-indigo-500 focus:blue-indigo-500 pl-9 pr-7 sm:text-sm"
                >
                  <option
                    v-for="(month, index) in localizedMonths"
                    v-bind:key="index"
                    :value="index"
                    :selected="index === passenger.birthdate.day"
                  >
                    {{ month }}
                  </option>
                </select>
              </div>
              <input
                v-model="passenger.birthdate.year"
                type="number"
                min="1900"
                :max="new Date().getFullYear()"
                name="phone_number"
                id="phone_number"
                class="block w-full pl-56 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-blue-500 sm:text-sm"
                placeholder="1986"
              />
            </div>
          </div>

          <div class="col-span-6 xl:col-span-3">
            <label
              for="street_address"
              class="block text-sm font-medium text-gray-700"
            >
              {{ $t('common.form.streetAddress') }}
            </label>
            <input
              v-model="passenger.streetAddress"
              type="text"
              name="street_address"
              id="street_address"
              autocomplete="street-address"
              class="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div class="col-span-3 xl:col-span-2">
            <label
              for="city"
              class="block text-sm font-medium text-gray-700"
              >
                {{ $t('common.form.city') }}
              </label>
            <input
              v-model="passenger.city"
              type="text"
              name="city"
              id="city"
              class="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <!-- <div class="col-span-6 sm:col-span-3 lg:col-span-2">
            <label
              for="state"
              class="block text-sm font-medium text-gray-700"
            >
                Région
                State / Province
            </label>
            <input
              type="text"
              name="state"
              id="state"
              class="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div> -->

          <div class="col-span-3 xl:col-span-1">
            <label
              for="postal_code"
              class="block text-sm font-medium text-gray-700"
              >                
                {{ $t('common.form.zipCode') }}
              </label>
            <input
              v-model="passenger.zipCode"
              type="number"
              name="zip_code"
              id="zip_code"
              autocomplete="zip-code"
              class="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
      <div
        class="inline-flex justify-between w-full px-4 py-3 text-right bg-gray-50 sm:px-6"
      >
        <button>
          <router-link
            :to="{ name: 'FunnelHomepage' }"
            class="inline-flex py-2 font-medium text-gray-700"
          >
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <!-- Retour -->
            {{ $t('common.back') }}
          </router-link>
        </button>
        <button
          :disabled=waitingForApiResponse
          type="submit"
          class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <div v-if=!waitingForApiResponse>
            <!-- Suivant -->
            {{ $t('common.next') }}
          </div>
          <pulse-loader v-else color="#fff" size="6px" />
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { API } from "@/interfaces"
import { axios, config } from "@/utils";
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js'

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

function getMonthsForLocale() {
  const SAFARI_OFFSET_FIX = 1;
  const months = [];

  for (let month = 0; month < 12; month++) {
    const localizedMonth = new Date(0, month, 1 + SAFARI_OFFSET_FIX, 0, 0, 0).toLocaleString('default', { month: 'long' })
    months.push(localizedMonth);
  }

  return months;
}

export default {
  components: {
    PulseLoader
  },
  data() {
    return {
      isPassengerDataFound: false,
      waitingForApiResponse: false,
      countries: config.EU_COUNTRIES_LIST,
      failedToFetchData: null,
      localizedMonths: getMonthsForLocale(),
      passenger: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        phoneCountryCode: '',
        birthdate: {
          day: 1,
          month: 1,
          year: 1990
        },
        nationalityCountryCode: '',
        streetAddress: '',
        city: '',
        zipCode: '',
      },
      notification: {
        title: 'Étape validée',
        message: 'Votre identitée a bien été confirmée',
        isSuccess: true,
        isDisplayed: true,
        duration: 4000
      },
    };
  },
  async mounted() {
    try {
      const res = await axios.get(`/passengers/funnel/${this.$route.params.slug}`);
      const funnelData = res.data as API.RetrieveFunnelResponse;
      const { user } = funnelData.claim;
      const { id, ...passenger } = user;

      const phone = parsePhoneNumber(passenger.phone, 'FR')
      const birthdate = new Date(passenger.birthdate)

      this.passenger = {
        ...passenger,
        phone: phone.formatNational(),
        phoneCountryCode: phone.country || 'FR',
        birthdate: {
          day: birthdate.getDate(),
          month: birthdate.getMonth(),
          year: birthdate.getFullYear()
        },
      };
      this.failedToFetchData = false
    } catch (error) {
      this.failedToFetchData = true
      console.log(">>> error", error);
    }
  },
  computed: {
    phoneNumber: {
      get() {
        return this.passenger.phone
      },
      set(newValue) {
        const phone = new AsYouType(this.passenger.phoneCountryCode) as any //@TODO fix type error
        phone.input(newValue)

        if (phone.country !== this.passenger.phoneCountryCode) {
          this.passenger.phoneCountryCode = phone.country
        }

        this.passenger.phone = phone.formattedOutput
      }
    },
    phoneNumberCountryCode: {
      get() {
        return this.passenger.phoneCountryCode
      },
      set(newCountryCode) {
        this.passenger.phoneCountryCode = newCountryCode
        const phone = new AsYouType(newCountryCode) as any //@TODO fix type error
        phone.input(this.passenger.phone)
        const callingCode = phone.getCallingCode()
        if (callingCode) {
          const nationalPhone = phone.getChars().split(callingCode)[1]
          this.passenger.phone = new AsYouType(newCountryCode).input('0' + nationalPhone)
        } else {
          this.passenger.phone = phone.formattedOutput
        }
      }
    }
  },
  methods: {
    async saveIdentity(e) {
      e.preventDefault();
      try {
        const payload = {
          ...this.passenger,
          phone: parsePhoneNumber(this.passenger.phone, this.passenger.phoneCountryCode)?.number,
          birthdate: new Date(
            this.passenger.birthdate.year,
            this.passenger.birthdate.month,
            this.passenger.birthdate.day,
            12 // Prevent UTC diff
          )
        }

        delete payload.email
        delete payload.phoneCountryCode

        const slug = this.$route.params.slug
        this.waitingForApiResponse = true
        await axios.patch(`/passengers/funnel/${slug}/confirm-identity`, payload);

        this.$router.push({ name: "FunnelWarrant", params: this.$route.params })
      } catch (error) {
        this.notification = {
          title: 'Invalid form',
          message: error.response?.data?.message || error.message,
          isSuccess: false,
          duration: 6000
        }
      } finally {
        this.waitingForApiResponse = false
        this.emitNotification()
      }
    },

    emitNotification() {
      this.$emit('showNotification', this.notification)
    }
  }
};
</script>

<style>
</style>