<template>
<!-- <transition
  enter-active-class="transition duration-300 ease-out transform"
  enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
  enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
  leave-active-class="transition duration-100 ease-in"
  leave-class="opacity-100"
  leave-to-class="opacity-0"
> -->
<transition name="slide-fade">
<div v-if="isDisplayed" class="fixed inset-0 z-40 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
  <div class="w-full max-w-sm bg-white rounded-lg shadow-lg pointer-events-auto" z-index="999">
    <div class="overflow-hidden rounded-lg shadow-xs">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <!-- Heroicon name: check-circle -->
            <svg v-if="isSuccess" class="w-6 h-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <!-- Heroicon name: x-circle -->
            <svg v-if="isSuccess === false" class="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium leading-5 text-gray-900 title">
              {{title}}
            </p>
            <p class="mt-1 text-sm leading-5 text-gray-500" v-html=message></p>
          </div>
          <div class="flex flex-shrink-0 ml-4">
            <button @click="hide" class="inline-flex text-gray-400 transition duration-150 ease-in-out focus:outline-none focus:text-gray-500">
              <!-- Heroicon name: x -->
              <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</transition>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
    isSuccess: Boolean,
    isDisplayed: Boolean,
    duration: {
      required: true,
      type: Number,
      default: 3000
    },
  },
  methods: {
    hide() {
      this.$emit('setNotificationDisplay', false)
    },
    show() {
      this.$emit('setNotificationDisplay', true)
      setTimeout(() => this.hide(), this.duration)
    }
  },
  watch:  {
    isDisplayed(newValue) {
      if (newValue === true) {
        setTimeout(() => this.hide(), this.duration)
      }
    }
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>