<template>
<div>
  <form @submit="signWarrant">
      <div class="px-4 py-5 bg-white sm:p-6">

        <div class="grid grid-cols-6 gap-6">

        </div>
      </div>
      <div
        class="px-4 py-3 inline-flex justify-between w-full bg-gray-50 text-right sm:px-6"
      >
        <button>
          <router-link
            :to="{ name: 'FunnelConfirmIdentity' }"
            class="text-gray-700 inline-flex py-2 font-medium"
          >
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Retour
          </router-link>
        </button>
        <button
          type="submit"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Suivant
        </button>
      </div>
  </form>
  </div>
</template>

<script>
// import { axios } from "@/utils";

export default {
  components: {},
  data() {
    return {
      failedToFetchData: null,
      alreadyUploaded: false,
    };
  },
  async mounted() {
    try {
      // const { data: funnelData } = await axios.get(`/passengers/funnel/${this.$route.params.slug}`);

      this.failedToFetchData = false
    } catch (error) {
      this.failedToFetchData = true
      console.log(">>> error", error);
    }
  },
  computed: {
  },
  methods: {
    async signWarrant(e) {
      e.preventDefault();
      this.$router.push({ name: 'FunnelLegalDocs' })
    }
  }
};
</script>

<style>
</style>