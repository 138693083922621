
import { API } from "@interfaces";
import { config, classListToObject } from '@/utils'

import HeroIcons from './ui/HeroIcons.vue'

// @TODO: i18n transformer
function boldFilter(text) {
  return `<span class="font-medium text-gray-900">${text}</span>`
}

export default {
  components: {
    HeroIcons,
  },
  name: 'ClaimTimelineElement',
  props: {
    event: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    timelineLength: {
      type: Number,
      required: true
    },
  },
  methods: {
    retrieveClaim() : API.ClaimDetail {
      return this.$store.getters.claims.find(claim => claim.id === this.event.claimId)
    },
  },
  computed: {
    date() {
      return new Date(this.event.event_date)
    },
    eventType() {
      return this.event.claims_events_types.type
    },
    eventSpecification() {
      return config.CLAIMS_EVENTS[this.event.claimEventType.type]
    },
    i18nFormattedParams() {
      const claim: API.ClaimDetail = this.retrieveClaim()

      const compensationAmount = claim?.eligibility.ec261CompensationAmount;
      const airlineName = claim?.userFlight.flight.airline.name;

      return {
        compensationAmount: boldFilter(`${compensationAmount}€`),
        airlineName: boldFilter(airlineName)
      }
    },
    iconClass() {
      const { class: cssClass } = config.CLAIMS_EVENTS[this.event.claimEventsTypes.type].heroIcon
      return classListToObject(cssClass)
    },
    isLast() {
      return (this.index + 1) === this.timelineLength
    }
  }
}
