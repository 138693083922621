export default {
  fr: {
    pages: {
      dashboard: {
        welcome: 'Bienvenue, {fullName}',
        overview: 'Dossier en cours | Dossiers en cours',
        recentActivity: 'Activité récente',
      },
      claimDetails: {
        yourDocuments: 'Vos documents',
      },
      login: {
        signInTitle: 'Se connecter à votre compte',
        resetPassword: 'Mot de passe oublié ?',
        rememberMe: 'Se rappeler de moi',
        signInButton: 'Se connecter'
      },
    },
    common: {
      event: 'Événement',
      view: 'Voir en détail',
      flight: 'Vol',
      compensation: 'Indemnisation',
      status: 'Statut',
      departure: 'Départ',
      arrival: 'Arrivée',
      delay: 'Retard',
      cancellation: 'Annulation',
      soon: 'Bientôt',
      next: 'Suivant',
      back: 'Retour',
      form: {
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        phoneNumber: 'Numéro de téléphone',
        emailAddress: 'Adresse email',
        password: 'Mot de passe',
        birthdate: 'Date de naissance',
        nationality: 'Nationalité',
        streetAddress: 'Adresse',
        city: 'Ville',
        zipCode: 'Code postal',
      }
    },
    menu: {
      home: 'Accueil',
      history: 'Historique',
      claims: 'Réclamations',
      payments: 'Paiements',
      groups: 'Groupes',
      params: 'Paramètres',
      help: 'Aide',
      logout: 'Déconnexion',
    },
    timeline: {
      CLAIM_CREATED: 'Votre vol est eligible à une indemnisation de {compensationAmount}',
      CLAIM_FILLED: 'Constitution du dossier par nos juristes (maximum 15 jours)',
      CLAIM_CANCELLED: '',
      COMPANY_CONTACTED: "Envoie d'une réclamation à {airlineName}",
      COMPROMISE_IN_PROGRESS: '',
      MED_SENT_BECAUSE_NO_RESPONSE: "Envoie d'une mise en demeure à {airlineName}",
      MED_SENT_BECAUSE_COMPROMISED_FAILED: '',
      COMPROMISE_SUCCESSFUL: "{airlineName} accepte de verser l'indemnisation",
      COMPROMISE_AMOUNT_PROPOSAL: '',
      COMPROMISE_AMOUNT_PROPOSAL_ACCEPTED: '',
      COMPROMISE_AMOUNT_PROPOSAL_REFUSED: '',
      COURT_SEIZED_BECAUSE_NO_RESPONSE: '',
      COURT_SEIZED_BECAUSE_COMPROMISED_FAILED: '',
      COURT_SEIZED_BECAUSE_FUNDS_NOT_RECEIVED: '',
      TRIAL_DATE_KNOWN: '',
      TRIAL_WON: '',
      TRIAL_LOST: '',
      FUNDS_RECEIVED: "Nous avons receptionné les fonds sur compte CARPA",
      PAYMENT_SENT: "L'indemnisation a été envoyée sur votre compte bancaire",
      MED_READ_NOTICE_RECEIVED: "Nous avons reçu l'accusé de réception",
      LAWYER_ASSIGNED: '',
      MENTIONNED_BY_LAWYER: '',
      TRIAL_POSTPONED: '',
      BAILIFF_SEIZED_BECAUSE_FUNDS_NOT_RECEIVED: ''
    }
  },
  ///////////////////////////////////////////
  en: {
    pages: {
      dashboard: {
        welcome: 'Welcome, {fullName}',
        overview: 'Current claim | Current claims',
        recentActivity: 'Recent activity',
      },
      claimDetails: {
        yourDocuments: 'Your documents',
      },
      login: {
        signInTitle: 'Sign in to your account',
        resetPassword: 'Forgot your password?',
        rememberMe: 'Remember me',
        signInButton: 'Sign in'
      },
    },
    common: {
      event: 'Event',
      view: 'View',
      flight: 'Flight',
      compensation: 'Compensation',
      status: 'Status',
      departure: 'Departure',
      arrival: 'Arrival',
      delay: 'Delay',
      cancellation: 'Cancellation',
      soon: 'Soon',
      next: 'Suivant',
      back: 'Retour',
      form: {
        firstName: 'First name',
        lastName: 'Last name',
        phoneNumber: 'Phone number',
        emailAddress: 'Email address',
        password: 'Password',
        birthDate: 'Birth date',
        nationality: 'Nationality',
        streetAddress: 'Street addresse',
        city: 'City',
        zipCode: 'ZIP / Postal',
      }
    },
    menu: {
      home: 'Home',
      history: 'History',
      claims: 'Claims',
      payments: 'Payments',
      groups: 'Groups',
      params: 'Settings',
      help: 'Help',
      logout: 'Logout',
    },
    timeline: {
      CLAIM_CREATED: 'Votre vol est eligible à une indemnisation de {compensationAmount}€',
      CLAIM_FILLED: 'Constitution du dossier par nos juristes (maximum 15 jours)',
      CLAIM_CANCELLED: '',
      COMPANY_CONTACTED: "Envoie d'une réclamation à ${companyName}",
      COMPROMISE_IN_PROGRESS: '',
      MED_SENT_BECAUSE_NO_RESPONSE: "Envoie d'une mise en demeure à ${companyName}",
      MED_SENT_BECAUSE_COMPROMISED_FAILED: '',
      COMPROMISE_SUCCESSFUL: "${companyName} accepte de verser l'indemnisation",
      COMPROMISE_AMOUNT_PROPOSAL: '',
      COMPROMISE_AMOUNT_PROPOSAL_ACCEPTED: '',
      COMPROMISE_AMOUNT_PROPOSAL_REFUSED: '',
      COURT_SEIZED_BECAUSE_NO_RESPONSE: '',
      COURT_SEIZED_BECAUSE_COMPROMISED_FAILED: '',
      COURT_SEIZED_BECAUSE_FUNDS_NOT_RECEIVED: '',
      TRIAL_DATE_KNOWN: '',
      TRIAL_WON: '',
      TRIAL_LOST: '',
      FUNDS_RECEIVED: "Nous avons receptionné les fonds sur compte CARPA",
      PAYMENT_SENT: "L'indemnisation a été envoyée sur votre compte bancaire",
      MED_READ_NOTICE_RECEIVED: "Nous avons reçu l'accusé de réception",
      LAWYER_ASSIGNED: '',
      MENTIONNED_BY_LAWYER: '',
      TRIAL_POSTPONED: '',
      BAILIFF_SEIZED_BECAUSE_FUNDS_NOT_RECEIVED: ''
    }
  },
}