<template>
<div>
  <SuccessModal
    :isDisplayed=showModal
    @handleModalButton=handleModalButton
    title="Réclamation enregistrée"
    message="Nous reviendrons vers vous sous 15 jours maximum, le temps pour nous de constituer un dossier complet."
    closeButtonText="Accéder au dashboard"
  />
  <form @submit="definePassword">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 sm:col-span-3">
            <label
              for="password"
              class="block text-sm font-medium text-gray-700"
            >
              Mot de passe
            </label>
            <input
              v-model="form.password"
              type="password"
              name="password"
              id="password"
              class="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label
              for="password_repeat"
              class="block text-sm font-medium text-gray-700"
            >
              Vérification du mot de passe
            </label>
            <input
              v-model="form.passwordVerification"
              type="password"
              name="password_repeat"
              id="password_repeat"
              class="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

        </div>
      </div>
      <div
        class="inline-flex justify-between w-full px-4 py-3 text-right bg-gray-50 sm:px-6"
      >
        <button>
          <router-link
            :to="{ name: 'FunnelLegalDocs' }"
            class="inline-flex py-2 font-medium text-gray-700"
          >
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Retour
          </router-link>
        </button>
        <button
          :disabled=waitingForApiResponse
          type="submit"
          class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <div v-if=!waitingForApiResponse>
            Suivant
          </div>
          <pulse-loader v-else color="#fff" size="6px" />
        </button>
      </div>
  </form>
  </div>
</template>

<script>
import { axios, http } from "@/utils";

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import SuccessModal from "@/components/ui/SuccessModal.vue"

export default {
  components: {
    PulseLoader,
    SuccessModal,
  },
  data() {
    return {
      waitingForApiResponse: false,
      failedToFetchData: null,
      alreadyUploaded: false,
      showModal: false,
      form: {
        password: null,
        passwordVerification: null
      },
      notification: {
        title: 'Invalid Password',
        message: '',
        isSuccess: false,
        duration: 5000
      },
    };
  },
  computed: {
  },
  methods: {
    async definePassword(e) {
      e.preventDefault();

      if (!this.form.password) {
        this.emitNotification({ message: 'Empty password is not allowed'})
        return
      } else if (this.form.password !== this.form.passwordVerification) {
        this.emitNotification({ message: 'Password and password verification mismatch' })
        return
      }


      try {
        const slug = this.$route.params.slug
        this.waitingForApiResponse = true
        const { data } = await axios.post(`/passengers/funnel/${slug}/define-password`, {
          password: this.form.password
        });

        const { data: claims } = await http.claims.list()
        this.$store.commit('setClaims', claims)
        this.$store.commit('setProfile', data.profile)
        this.$store.commit('setAuthenticated')
        this.showModal = true
      } catch (error) {
        this.emitNotification({ message: error.response?.data?.message || error.message })
      } finally {
        this.waitingForApiResponse = false
      }
    },
    emitNotification(notification = {}) {
      this.$emit('showNotification', {...this.notification, ...notification})
    },
    handleModalButton() {
      this.$router.push('/')
    }
  }
};
</script>

<style>
</style>