<template>
<div class="sticky flex h-screen overflow-hidden bg-gray-100">
  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div class="lg:hidden">
    <div v-show=isMobileMenuOpened class="fixed inset-0 z-40 flex">
      <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      -->
    <transition
      enter-active-class="transition-opacity duration-300 ease-linear"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-300 ease-linear"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show=isMobileMenuOpened class="fixed inset-0">
        <div class="absolute inset-0 bg-gray-600 opacity-75" aria-hidden="true"></div>
      </div>
    </transition>
      <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
    <transition
      enter-active-class="transition duration-300 ease-in-out transform"
      enter-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transition duration-300 ease-in-out transform"
      leave-class="translate-x-0"
      leave-to-class="-translate-x-full"
    >
      <div v-show="isMobileMenuOpened" class="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-blue-700">
        <div class="absolute top-0 right-0 pt-2 -mr-12">
          <button @click="isMobileMenuOpened=false" class="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Close sidebar</span>
            <!-- Heroicon name: x -->
            <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="flex items-center flex-shrink-0 px-4 text-2xl font-bold text-white">
          <img class="w-auto h-8 mr-2" src="@/assets/logo.svg" alt="Upclaim logo">
          Upclaim
        </div>
        <nav class="flex-shrink-0 h-full mt-5 overflow-y-auto divide-y divide-blue-800" aria-label="Sidebar">
          <div class="px-2 space-y-1">
            <!-- Current: "bg-blue-800 text-white", Default: "text-blue-100 hover:text-white hover:bg-blue-600" -->
            <router-link to="/" class="flex items-center px-2 py-2 text-base font-medium text-white bg-blue-800 rounded-md group" aria-current="page">
              <!-- Heroicon name: home -->
              <svg class="w-6 h-6 mr-4 text-blue-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              Home
            </router-link>

            <a href="#" class="flex items-center px-2 py-2 text-base font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-600 group">
              <!-- Heroicon name: clock -->
              <svg class="w-6 h-6 mr-4 text-blue-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              History
            </a>

            <router-link to="/claims/b2df9294-99f3-4bda-807f-4c5c09a4b8ec" class="flex items-center px-2 py-2 text-base font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-600 group">
              <!-- Heroicon name: scale -->
              <svg class="w-6 h-6 mr-4 text-blue-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
              </svg>
              Claims
            </router-link>

            <a href="#" class="flex items-center px-2 py-2 text-base font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-600 group">
              <!-- Heroicon name: credit-card -->
              <svg class="w-6 h-6 mr-4 text-blue-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
              </svg>
              Cards
            </a>

            <a href="#" class="flex items-center px-2 py-2 text-base font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-600 group">
              <!-- Heroicon name: user-group -->
              <svg class="w-6 h-6 mr-4 text-blue-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
              Groupes
            </a>
          </div>
          <div class="pt-6 mt-6">
            <div class="px-2 space-y-1">
              <a href="#" class="flex items-center px-2 py-2 text-base font-medium text-blue-100 rounded-md group hover:text-white hover:bg-blue-600">
                <!-- Heroicon name: cog -->
                <svg class="w-6 h-6 mr-4 text-blue-200 group-hover:text-blue-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                Settings
              </a>

              <a href="#" class="flex items-center px-2 py-2 text-base font-medium text-blue-100 rounded-md group hover:text-white hover:bg-blue-600">
                <!-- Heroicon name: question-mark-circle -->
                <svg class="w-6 h-6 mr-4 text-blue-300 group-hover:text-blue-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Aide
              </a>

              <!-- <a href="#" class="flex items-center px-2 py-2 text-base font-medium text-blue-100 rounded-md group hover:text-white hover:bg-blue-600">
                <svg class="w-6 h-6 mr-4 text-blue-300 group-hover:text-blue-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
                Privacy
              </a> -->
            </div>
          </div>
        </nav>
      </div>
    </transition>

      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </div>
  </div>

  <!-- Static sidebar for desktop -->
  <div class="hidden md:flex md:flex-shrink-0">
    <div class="flex flex-col w-64">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto bg-blue-700">
        <div class="flex items-center flex-shrink-0 px-4 mx-auto text-2xl font-bold text-white">
          <img class="w-auto h-6 my-2 mr-2" src="@/assets/logo-full-white.svg" alt="Upclaim logo">
            <!-- Upclaim -->
        </div>
        <nav class="flex flex-col flex-1 mt-5 overflow-y-auto divide-y divide-blue-800" aria-label="Sidebar">
          <div class="px-2 space-y-1">
            <NavItem
              :isActive="isCurrentPage('Dashboard')"
              linkUrl="/"
              :text="$t('menu.home')"
              iconName="home"
            />
            <!-- <NavItem :isActive="isCurrentPage('history')" linkUrl="#" :text="$t('menu.history')" iconName="clock" /> -->
            <NavItem
              :isActive="false"
              linkUrl="#"
              :text="$t('menu.claims')"
              iconName="scale"
            >
              <!-- <HeroIcons name='chevron-down' class="w-5 h-5 mr-2" /> -->
            </NavItem>
              <div class="space-y-1">
                <div v-for="claim in currentClaims" v-bind:key="claim.id">
                  <NavItem
                    :isActive="isCurrentPage('ClaimDetails', claim.id)"
                    :text="`Vol ${claim.userFlight.flight.flightIdentifier}`"
                    :linkUrl="`/claims/${claim.id}`"
                    class="pl-10"
                    iconName='chevron-double-right'
                  >
                    <!-- <HeroIcons name='chevron-double-right' class="w-5 h-5 mx-2" /> -->
                    <!-- <badge text="1" class="mx-2 text-red-100 uppercase bg-red-700" /> -->
                  </NavItem>
                </div>
                <!-- <a href="#" class="flex items-center w-full py-2 pl-10 pr-2 text-sm font-medium text-blue-200 rounded-md group hover:bg-blue-600">
                  <HeroIcons name='dots-horizontal' class="w-5 h-5 mx-2" />
                  Tout voir
                </a> -->
              </div>
            <NavItem :isActive="isCurrentPage('payments')" linkUrl="#" :text="$t('menu.payments')" iconName="credit-card" />
            <!-- <NavItem :isActive="isCurrentPage('groups')" linkUrl="#" :text="$t('menu.groups')" iconName="user-group" /> -->
          </div>
          <div class="pt-6 mt-6">
            <div class="px-2 space-y-1">
              <NavItem :isActive="isCurrentPage('params')" linkUrl="#" :text="$t('menu.params')" iconName="cog" />
              <NavItem :isActive="isCurrentPage('help')" linkUrl="#" :text="$t('menu.help')" iconName="question-mark-circle" />
              <NavItem @click="logout" linkUrl="#" :text="$t('menu.logout')" iconName="logout" />

              <!-- <a href="#" class="flex items-center px-2 py-2 text-sm font-medium leading-6 text-blue-100 rounded-md group hover:text-white hover:bg-blue-600">
                <svg class="w-6 h-6 mr-4 text-blue-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
                Privacy
              </a> -->
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <div class="flex-1 overflow-auto focus:outline-none" tabindex="0">
    <div v-show="isMobileMenuOpened" class="relative z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 lg:border-none">
      <button @click="isMobileMenuOpened=true" class="px-4 text-gray-400 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 lg:hidden">
        <span class="sr-only">Open sidebar</span>
        <!-- Heroicon name: menu-alt-1 -->
        <svg class="block w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
    </div>
    <router-view></router-view>
  </div>
</div>

</template>

<script>
import * as Sentry from '@sentry/browser'
import { http, claims, axios } from '../utils'

import NavItem from '@/components/NavItem.vue'
// import HeroIcons from '../components/ui/HeroIcons.vue';
// import Badge from '../components/ui/Badge.vue';

export default {
  name: 'DashboardLayout',
  components: {
    NavItem,
    // HeroIcons,
    // Badge,
  },
  async beforeMount() {
    const claims = await this.$store.getters.claims;
    if (!claims) {
      try {
        const { data } = await http.claims.list()
        this.$store.commit('setClaims', data)
      } catch (error) {
        // @TODO: Notification error
        console.log('>>>> error', error)
      }
    }
  },
  mounted() {},
  data() {
    return {
      isMobileMenuOpened: false,
      isDropdownOpened: false
    }
  },
  computed: {
    claims() {
      return this.$store.getters.claims;
    },
    currentClaims() {
      return claims.getCurrentClaims(this.claims)
    }
  },
  methods: {
    closeMobileMenu() {
      this.isMobileMenuOpen = false
    },
    openMobileMenu() {
      this.isMobileMenuOpen = true
    },
    logout() {
      axios.get('/auth/logout', {withCredentials: true})
      this.$store.commit('setUnauthenticated')
      this.$router.push('/login')

      const email = this.$store.getters.profile?.email || "anonymous";
      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'User logged out ' + email,
        level: Sentry.Severity.Info
      });
      Sentry.setUser(null);
    },
    isCurrentPage(pageName, id) {
      return pageName === this.$route.name
        && (id ? id === this.$route.params.id : true)
    }
  }
}
</script>

<style>

</style>