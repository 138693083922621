import dotenv from "dotenv";
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { Offline, Transaction } from "@sentry/integrations";
import { version } from "../package.json"
// import mixpanel from 'mixpanel-browser'

import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import i18nMessages from './utils/i18n'
import { config } from './utils'

import router from './router'
import store from './store'
import App from './App.vue'

import './assets/stylesheet/index.css'

dotenv.config();
Sentry.init({
  dsn: config.SENTRY_DSN,
  attachStacktrace: true,
  autoSessionTracking: true,
  enabled: process.env.NODE_ENV !== "development",
  environment: process.env.NODE_ENV,
  release: 'web-app@' + version,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [config.API_URL, "localhost", "upclaim.eu", /^\//],
    }),
    new Sentry.Integrations.Breadcrumbs({
      xhr: true,
      fetch: true,
    }),
    new Transaction(),
    new Offline(),
  ],
  tracesSampleRate: 1.0
})

const app = createApp(App)

const i18n = createI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'en',
  messages: i18nMessages
})

app.config.errorHandler = (err, vm, info) => {
  Sentry.setTag('info', info)
  Sentry.captureException(err)
}
window.addEventListener('error', (event) => {
  Sentry.captureException(event)
})
window.addEventListener('unhandledrejection', (event) => {
  Sentry.captureException(event)
})

app.use(i18n)
app.use(router)
app.use(store)
app.mount('#app')

// mixpanel.init("edf1acc78de332cbf15d4b386c4b363a", { "api_host": "https://api-eu.mixpanel.com" }, "");
