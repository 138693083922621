<template>
  <div class="px-4 py-3 bg-gray-50 text-center sm:px-6">
    <nav class="my-4 flex items-center justify-center" aria-label="Progress">
      <!-- <p class="text-sm text-gray-700">Étape 1/3</p> -->
      <ol class="ml-8 flex items-center space-x-5">
        <li v-for="funnelStep in steps" v-bind:key="funnelStep.step">
          <!-- Completed Step -->
          <!-- <pre>{{ funnelStep }}</pre> -->
          <div v-if="funnelStep.step < currentStep">
            <a
              href="#"
              class="block w-2.5 h-2.5 bg-indigo-600 rounded-full hover:bg-gray-400"
            >
              <span class="sr-only">{{ funnelStep.srOnly }}</span>
            </a>
          </div>
          <!-- Current Step -->
          <div v-else-if="funnelStep.step === currentStep">
            <a
              href="#"
              class="relative flex items-center justify-center"
              aria-current="step"
            >
              <span
                class="absolute w-5 h-5 p-px flex"
                aria-hidden="true"
              >
                <span
                  class="w-full h-full rounded-full bg-indigo-200"
                ></span>
              </span>
              <span
                class="relative block w-2.5 h-2.5 bg-indigo-600 rounded-full"
                aria-hidden="true"
              ></span>
              <span class="sr-only">{{ funnelStep.srOnly }}</span>
            </a>
          </div>

          <!-- Upcoming Step -->
          <div v-else-if="funnelStep.step > currentStep">
            <a
              href="#"
              class="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400"
            >
              <span class="sr-only">{{ funnelStep.srOnly }}</span>
            </a>
          </div>
        </li>
      </ol>
    </nav>
    <p class="inline-flex justify-center py-2 text-gray-900 font-medium">
      {{ currentStepTitle }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    slug: String,
    currentStep: Number
  },
  data() {
    return {
      steps: [
        { step: 1, title: 'Vérifier vos informations personnelles', srOnly: 'Step 1 - Confirm Identity' },
        { step: 2, title: 'Signature Électronique', srOnly: 'Step 2 - Warrant' },
        { step: 3, title: 'Documents Légaux', srOnly: 'Step 4 - Legal Docs' },
        { step: 4, title: 'Définir un mot de passe', srOnly: 'Step 3 - Define a password' },
      ]
    }
  },
  computed: {
    currentStepTitle() {
      const step = this.steps.find(step => step.step === this.currentStep)
      return step?.title
    }
  }
};
</script>

<style></style>
