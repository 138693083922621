<template>
<div class="min-h-screen bg-gray-100">
  <main class="py-10">
    <!-- Page header -->
    <div class="max-w-3xl px-4 mx-auto sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
      <div class="flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative">
            <img class="w-16 h-16 rounded-full" :src=$store.getters.profile.profilePictureUrl alt="">
            <span class="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></span>
          </div>
        </div>
        <div>
          <h1 class="text-2xl font-bold text-gray-900">{{ $store.getters.profile.fullName }}</h1>
          <p class="text-sm font-medium text-gray-500">Claim for <span class="text-gray-900">3 hours 42 minutes delay</span> on <time datetime="2020-08-25">August 25, 2020</time></p>
        </div>
      </div>
      <div class="flex flex-col-reverse mt-6 space-y-4 space-y-reverse justify-stretch sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <button type="button" class="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
          Deny offer
        </button>
        <button type="button" class="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
          Accept offer
        </button>
      </div>
    </div>

    <div class="grid max-w-3xl grid-cols-1 gap-6 mx-auto mt-8 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
      <!-- Description list-->
      <section class="col-span-1" aria-labelledby="applicant-information-title">
        <div class="bg-white shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" class="text-lg font-medium leading-6 text-gray-900">
              Informations du dossier
            </h2>
            <p class="max-w-2xl mt-1 text-sm text-gray-500">
              Personal details and application.
            </p>
          </div>
          <div class="px-4 py-5 border-t border-gray-200 sm:px-6">
            <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  {{ $t('common.flight') }}
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ claim?.userFlight.flight.flightIdentifier }}
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Date
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{new Date(claim?.userFlight.flight.scheduledDepartureUTC).toLocaleString()}}
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  {{ $t('common.departure') }}
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  Paris (CDG)
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  {{ $t('common.arrival') }}
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  San Francisco (SFO)
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  {{ $t('common.compensation') }}
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ claim?.eligibility.ec261CompensationAmount }}€
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Type
                </dt>
                <dd class="mt-1 bg-yellow-100 text-yellow-800 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium">
                  <p class="lowercase">Annulation</p>
                </dd>
              </div>
              <div class="col-span-2">
                <dt class="text-sm font-medium text-gray-500">
                  {{ $t('pages.claimDetails.yourDocuments') }}
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  <ul class="border border-gray-200 divide-y divide-gray-200 rounded-md">
                    <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                      <div class="flex items-center flex-1 w-0">
                        <!-- Heroicon name: paper-clip -->
                        <svg class="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                        </svg>
                        <span class="flex-1 w-0 ml-2 truncate">
                          rib-boursorama-banque (1).pdf
                        </span>
                      </div>
                      <div class="flex-shrink-0 ml-4">
                        <a href="#" class="font-medium text-blue-600 hover:text-blue-500">
                          Download
                        </a>
                      </div>
                    </li>
                    <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                      <div class="flex items-center flex-1 w-0">
                        <!-- Heroicon name: paper-clip -->
                        <svg class="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                        </svg>
                        <span class="flex-1 w-0 ml-2 truncate">
                          passport.pdf
                        </span>
                      </div>
                      <div class="flex-shrink-0 ml-4">
                        <a href="#" class="font-medium text-blue-600 hover:text-blue-500">
                          Download
                        </a>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
          <div>
            <!-- <a href="#" class="block px-4 py-4 text-sm font-medium text-center text-gray-500 bg-gray-50 hover:text-gray-700 sm:rounded-b-lg">Read full application</a> -->
          </div>
        </div>
      </section>
  
      <div class="space-y-6 lg:col-start-1 lg:col-span-2">
        <ClaimTimeline :claim="claim" />

        <!-- Comments-->
        <section aria-labelledby="notes-title">
          <div class="bg-white shadow sm:rounded-lg sm:overflow-hidden">
            <div class="divide-y divide-gray-200">
              <div class="px-4 py-5 sm:px-6">
                <h2 id="notes-title" class="text-lg font-medium text-gray-900">Informations complémentaires</h2>
              </div>
              <div class="px-4 py-6 sm:px-6">
                <ul class="space-y-8">
                  <li>
                    <div class="flex space-x-3">
                      <div class="flex-shrink-0">
                        <img class="w-10 h-10 rounded-full" src="https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                      </div>
                      <div>
                        <div class="text-sm">
                          <a href="#" class="font-medium text-gray-900">Maitre Depardieu</a>
                        </div>
                        <div class="mt-1 text-sm text-gray-700">
                          <p>
                            Je me présente, Maître Depardieu, avocat en charge de votre dossier.<br><br>
                            La compagnie m'indique que vous êtes arrivés avec 2h55 de retard et non 3h. Nous pensons que vous êtes arrivés avec 2h55 de retard sur la piste mais que l'ouverture des portes s'est effectuée avec plus de 3h de retard.<br>
                            Pouvez-vous me le confirmer ?<br><br>
                            Bien à vous,<br>
                          </p>
                        </div>
                        <div class="mt-2 space-x-2 text-sm">
                          <span class="font-medium text-gray-500">Oct 20</span>
                          <!-- <span class="font-medium text-gray-500">&middot;</span> -->
                          <!-- <button type="button" class="font-medium text-gray-900">Reply</button> -->
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div class="flex space-x-3">
                      <div class="flex-shrink-0">
                        <img class="w-10 h-10 rounded-full" :src=$store.getters.profile.profilePictureUrl alt="">
                      </div>
                      <div>
                        <div class="text-sm">
                          <a href="#" class="font-medium text-gray-900">{{ $store.getters.profile.fullName }}</a>
                        </div>
                        <div class="mt-1 text-sm text-gray-700">
                          <p>Je vais devoir payer quelque chose pour les avocats ?</p>
                        </div>
                        <div class="mt-2 space-x-2 text-sm">
                          <span class="font-medium text-gray-500">Oct 21</span>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div class="flex space-x-3">
                      <div class="flex-shrink-0">
                        <img class="w-10 h-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                      </div>
                      <div>
                        <div class="text-sm">
                          <a href="#" class="font-medium text-gray-900">Mathilde - Upclaim</a>
                        </div>
                        <div class="mt-1 text-sm text-gray-700">
                          <p>
                            Nous prenons en charge l'intégralité des frais d'avocats.<br>
                          </p>
                        </div>
                        <div class="mt-2 space-x-2 text-sm">
                          <span class="font-medium text-gray-500">Oct 21</span>
                          <!-- <span class="font-medium text-gray-500">&middot;</span> -->
                          <!-- <button type="button" class="font-medium text-gray-900">Reply</button> -->
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="px-4 py-6 bg-gray-50 sm:px-6">
              <div class="flex space-x-3">
                <div class="flex-shrink-0">
                  <img class="w-10 h-10 rounded-full" :src=$store.getters.profile.profilePictureUrl alt="">
                </div>
                <div class="flex-1 min-w-0">
                  <form action="#">
                    <div>
                      <label for="comment" class="sr-only">About</label>
                      <textarea id="comment" name="comment" rows="3" class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Add a note"></textarea>
                    </div>
                    <div class="flex items-center justify-between mt-3">
                      <a href="#" class="inline-flex items-start space-x-2 text-sm text-gray-500 group hover:text-gray-900">
                        <!-- Heroicon name: question-mark-circle -->
                        <svg class="flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                        </svg>
                        <span>
                          En savoir plus
                        </span>
                      </a>
                      <button type="submit" class="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        Envoyer
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</div>
</template>

<script lang="ts">
import { API } from "@interfaces";
import { http } from '../utils'
import ClaimTimeline from '@/components/ClaimTimeline2.vue'

export default {
  components: {
    ClaimTimeline
  },
  async beforeMount() {
    const { data } = await http.claims.retrieve(this.$route.params.id)
    // @TODO: Update store?
    this.claim = data as API.ClaimDetail;
  },
  data() {
    return {
      claim: null
    }
  },
  created() {
    this.$watch(() => this.$route.params, async (toParams, fromParams) => {
      if (!toParams.id || toParams.id === fromParams.id) {
        return
      }
      const claimId = toParams.id
      const { data } = await http.claims.retrieve(claimId)
      this.claim = data
    })
  },
}
</script>

<style>

</style>