import Axios, { AxiosRequestConfig } from 'axios'
import * as Sentry from '@sentry/browser'

import store from '@/store';
import router from '@/router';
import  config from '@/utils/config'

const axiosConfig = {
  baseURL: config.API_URL,
  timeout: 60000,
  headers: { 'Access-Control-Allow-Origin': config.API_URL }
};
const axios = Axios.create(axiosConfig);

axios.defaults.withCredentials = true;

export function setupAxiosInterceptors() : void {
  axios.interceptors.response.use(undefined, function onRejected(err) {
    return new Promise(() => {
      if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
        store.commit('setUnauthenticated');
        router.push('/login');
      } else { // Send to Sentry
        const email = store.getters.profile?.email || null;
        Sentry.configureScope((scope) => {
          scope.addBreadcrumb({
            type: "http",
            category: "xhr",
            message: "Response",
            data: err.response.data
          })
        })
    
        Sentry.captureException(err, {
          user: { email },
          tags: { axios: true },
        });
      }
      throw err;
    });
  });

  axios.interceptors.request.use(function onFulfilled(req: AxiosRequestConfig) {
    const transaction = Sentry.startTransaction({
      name: `${req.method.toUpperCase()} ${req.baseURL}`,
      op: "request"
    });
    Sentry.configureScope((scope) => {
      scope.setSpan(transaction);

      scope.addBreadcrumb({
        type: "http",
        category: "xhr",
        message: "Axios",
        data: {
          url: req.url,
          method: req.method.toUpperCase(),
          params: req.params,
          body: req.data
        }
      })
    });

    return req;
  });
}

export default axios