<template>
<div class="block">
  <section aria-labelledby="timeline-title">
    <div class="max-w-full">
      <div class="flex flex-col">
        <div class="min-w-full overflow-scroll align-middle shadow sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th colspan="2" class="px-6 py-5 text-left bg-white">
                  <h2 class="text-xl font-medium leading-6 text-gray-900">
                    Timeline
                  </h2>
                  <p class="max-w-2xl mt-1 text-sm font-normal text-gray-500">
                    Les derniers éléments de votre dossier.
                  </p>
                </th>
              </tr>
              <!-- <tr>
                <th id="timeline" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                  {{ $t('common.event') }}
                </th>
                <th class="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                  Date
                </th>
              </tr> -->
            </thead>
            <tbody class="bg-white">
              
              <ClaimTimelineElement
                v-for="(event, index) of claimEvents"
                v-bind:key="event.id"
                :event="event"
                :index="index"
                :timelineLength="claimEvents.length"
              />
              
              <!-- More items... -->
            </tbody>
          </table>
          <!-- Pagination -->
          <nav class="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6" aria-label="Pagination">
            <div class="hidden sm:block">
              <p class="text-sm text-gray-700">
                Showing
                <span class="font-medium">1</span>
                to
                <span class="font-medium">10</span>
                of
                <span class="font-medium">20</span>
                results
              </p>
            </div>
            <div class="flex justify-between flex-1 sm:justify-end">
              <a href="#" class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
                Previous
              </a>
              <a href="#" class="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
                Next
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script lang="ts">
import { API } from "interfaces";

import ClaimTimelineElement from './ClaimTimelineElement.vue'

export default {
  components: { ClaimTimelineElement },
  name: 'ClaimTimeline',
  props: {
    claim: Object
  },
  computed: {
    claimEvents() {
      const events: API.ClaimEvent[] = this.claim?.events;

      return events?.sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime())
    }
  }
}
</script>

<style>

</style>