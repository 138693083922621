<template>
  <tr class="bg-white hover:bg-gray-50">
    <td class="w-full px-6 py-4 text-sm text-gray-900 whitespace-normal max-w-0">
      <div class="relative flex">
        <!-- line -->
        <span v-if=!isLast class="absolute left-4 top-8 -ml-px h-full z-0 w-0.5 bg-gray-200" aria-hidden="true"></span>
        
        <a href="#" class="inline-flex space-x-2 text-sm group">

          <div class="z-10">
            <span :class="iconClass" class="flex items-center justify-center w-8 h-8 mr-2 bg-gray-400 rounded-full ring-4 ring-gray-100 hover:ring-gray-50">
              <HeroIcons
                :name=eventSpecification.heroIcon.name
                class="w-5 h-5 text-white"
                :solid=true
              />
            </span>
          </div>

          <div class="flex pt-1 text-gray-500 group-hover:text-gray-900">
            <p v-html="$t(`timeline.${this.eventType}`, i18nFormattedParams)"></p>
          </div>

        </a>
      </div>
    </td>
    <td class="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
      {{ date.toLocaleDateString() }}
    </td>
  </tr>
</template>

<script lang="ts">
import { API } from "@interfaces";
import { config, classListToObject } from '@/utils'

import HeroIcons from './ui/HeroIcons.vue'

// @TODO: i18n transformer
function boldFilter(text) {
  return `<span class="font-medium text-gray-900">${text}</span>`
}

export default {
  components: {
    HeroIcons,
  },
  name: 'ClaimTimelineElement',
  props: {
    event: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    timelineLength: {
      type: Number,
      required: true
    },
  },
  methods: {
    retrieveClaim() : API.ClaimDetail {
      return this.$store.getters.claims.find(claim => claim.id === this.event.claimId)
    },
  },
  computed: {
    date() {
      return new Date(this.event.event_date)
    },
    eventType() {
      return this.event.claims_events_types.type
    },
    eventSpecification() {
      return config.CLAIMS_EVENTS[this.event.claimEventType.type]
    },
    i18nFormattedParams() {
      const claim: API.ClaimDetail = this.retrieveClaim()

      const compensationAmount = claim?.eligibility.ec261CompensationAmount;
      const airlineName = claim?.userFlight.flight.airline.name;

      return {
        compensationAmount: boldFilter(`${compensationAmount}€`),
        airlineName: boldFilter(airlineName)
      }
    },
    iconClass() {
      const { class: cssClass } = config.CLAIMS_EVENTS[this.event.claimEventsTypes.type].heroIcon
      return classListToObject(cssClass)
    },
    isLast() {
      return (this.index + 1) === this.timelineLength
    }
  }
}
</script>

<style>

</style>