<template>
  <span
    :class="classObject"
    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium">
      {{ text }}
  </span>
</template>

<script>
import { classListToObject } from '@/utils';

export default {
  name: 'Badge',
  props: {
    text: {
      required: true,
      type: String,
      default: ''
    },
    class: {
      required: true,
      type: String,
      default: 'text-blue-800 bg-blue-100'
    },
  },
  computed: {
    classObject() {
      return classListToObject(this.class)
    }
  }
}
</script>

<style>

</style>