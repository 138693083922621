<template>
<div>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative pt-2 bg-white pb-14 sm:py-24">
    <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
      <div class="relative sm:py-16 lg:py-0">
        <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
          <div class="absolute inset-y-0 w-full right-1/2 bg-gray-50 rounded-r-3xl lg:right-72"></div>
          <svg class="absolute -ml-3 top-8 left-1/2 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
            <defs>
              <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-300" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
          </svg>
        </div>
        <div class="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
          <!-- Testimonial card-->
          <div class="relative pt-32 pb-2 overflow-hidden shadow-xl sm:pt-64 sm:pb-10 rounded-2xl">
            <img
              class="absolute inset-0 object-cover w-full h-full"
              src="https://cdn.dribbble.com/users/749358/screenshots/5434030/plane_4x.png?compress=1&resize=1600x1200"
              alt=""
            >
            <!-- <div class="absolute inset-0 bg-indigo-500" style="mix-blend-mode: multiply;"></div> -->
            <!-- <div class="absolute inset-0 bg-gradient-to-t from-blue-100 via-blue-200 opacity-10"></div> -->
            <div class="relative px-8 mt-16 sm:mt-0">
              <!-- <div> -->
                <!-- <img class="h-12" src="https://tailwindui.com/img/logos/workcation.svg?color=white" alt="Workcation"> -->
              <!-- </div> -->
              <blockquote class="mt-8">
                <div class="relative text-lg font-medium text-white md:flex-grow">
                  <svg class="absolute top-0 left-0 w-8 h-8 text-indigo-900 transform -translate-x-3 -translate-y-2" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p class="relative">
                    Vol AF80 - Retardé de 3h45
                  </p>
                </div>

                <!-- <footer class="mt-4">
                  <p class="text-base font-semibold text-indigo-200">Sarah Williams, CEO at Workcation</p>
                </footer> -->
              </blockquote>
            </div>
          </div>
        </div>
      </div>

      <div class="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0">
        <!-- Content area -->
        <div class="pt-12 sm:pt-16 lg:pt-20">
          <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            🇪🇺 Votre vol a été perturbé
          </h2>
          <div class="mt-6 space-y-6 text-gray-500">
            <p class="text-lg">
              Vous venez de subir un retard de plus de 3h et conformément au <a class="font-medium text-blue-950" href="">règlement européen 261/2004</a> la compagnie aérienne doit vous indemniser.
            </p>
            <p class="text-base leading-7">
              Grâce au partenariat que votre agence de voyage a souhaité établir avec nous, il n’y aura aucune commission. Notre solution est <span class="font-medium text-blue-950">entièrement gratuite</span> pour vous. 
            </p>
          </div>
        </div>

        <!-- Stats section -->
        <div class="mt-10">
          <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
            <div class="pt-6 border-t-2 border-gray-100">
              <dt class="text-base font-medium text-gray-500">Type (Retard)</dt>
              <dd class="text-3xl font-extrabold tracking-tight text-gray-900">3h45</dd>
            </div>

            <div class="pt-6 border-t-2 border-gray-100">
              <dt class="text-base font-medium text-gray-500">Indemnisation</dt>
              <dd class="text-3xl font-extrabold tracking-tight text-gray-900">600€</dd>
            </div>
          </dl>
          <div class="mt-10">
            <router-link :to="{ name: 'FunnelConfirmIdentity' }" class="text-base font-medium text-indigo-600">
              Commencer le formulaire d'indemnisation <span aria-hidden="true">&rarr;</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
  methods: {
    nextStepFunnel() {
      this.$router.push()
    }
  }
}
</script>

<style>
</style>