<template>
<div class="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
  <div class="space-y-1 text-center">
    <svg v-if=isEmpty class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
      <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-else class="mx-auto w-12 h-12 text-green-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
    </svg>
    <div class="text-sm text-gray-600">
      <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-700 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
        <p class="py-1 font-bold text-gray-600" v-if=!isEmpty>{{ fileName }}</p>
        <span @click="openFileSelection()">{{ isEmpty ? uploadFileLinkText : changeFileLinkText }}</span>
        <input
          v-on:change="handleFileChange()"
          type="file"
          class="sr-only"
          accept="image/jpeg,image/png,application/pdf"
          ref="file"
          :id=fieldName
        />
      </label>
      <p class="py-1" v-if=isEmpty>{{ description }}</p>
    </div>
    <p class="text-xs text-gray-500" v-show=isEmpty>
      {{ allowedFiles }}
    </p>
  </div>
</div>
</template>

<script>
export default {
  props: {
    fieldName: String,
    uploadFileLinkText: {
      type: String,
      default: 'Upload a file'
    },
    changeFileLinkText: {
      type: String,
      default: 'Change file'
    },
    description: String,
    allowedFiles: {
      type: String,
      default: 'PNG, JPG, GIF up to 250MB' 
    }
  },
  data() {
    return {
      isEmpty: true,
      fileName: ''
    }
  },
  methods: {
    openFileSelection() {
      this.$refs['file'].click()
    },
    handleFileChange() {
      const filePath = this.$refs['file'].value;
      if (filePath) {
        this.fileName = filePath.substr(filePath.lastIndexOf('\\') + 1);
        this.isEmpty = false
        this.$emit('handleFileChange', this.$props.fieldName, this.$refs['file'].files[0])
      } else { // No file selected
        this.isEmpty = true
        this.$emit('handleFileChange', this.$props.fieldName, null)
      }
    }
  }
}
</script>

<style>

</style>