import axios from '../../axios'

class httpClaims {
  static async list() {
    return axios.get('/claims')
  }

  static async retrieve(claimId: string) {
    return axios.get(`/claims/${claimId}`)
  }
}

export default httpClaims