<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition :name="$route.meta.transitionName">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'

import { setupAxiosInterceptors } from '@/utils/axios'



function storeUserLanguage(store) {
  const userLang = navigator.language || navigator["userLanguage"];
  store.commit('setUserLanguage', userLang)
}

export default {
  name: 'Upclaim',
  created() {
    setupAxiosInterceptors()
    storeUserLanguage(this.$store)

    const email = this.$store.getters.profile?.email || null;
    Sentry.startTransaction({ name: "page-load" });
    Sentry.setUser({ email });
  },
  components: {

  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
</style>
