<script>
import { h } from 'vue'

import * as Outline from '@heroicons/vue/outline';
import * as Solid from '@heroicons/vue/solid';

function toPascalCase(string) {
  return `${string}`
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w+)/, 'g'),
      ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
    )
    .replace(new RegExp(/\s/, 'g'), '')
    .replace(new RegExp(/\w/), s => s.toUpperCase());
}

export default {
  render() {
    const heroIconName = toPascalCase(this.name) + 'Icon'
    const IconStyle = this.solid ? Solid : Outline

    return h(IconStyle[heroIconName], { class: this.class })
  },
  props: {
    name: {
      type: String,
      required: true
    },
    class: String,
    solid: {
      type: Boolean,
      default: false
    }
  }
}
</script>