<template>
<div>
  <form @submit="uploadLegalDocs">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6">
            <label
              for="id_proof"
              class="block text-sm font-medium text-gray-700"
            >
              Pièce d'identité
            </label>
            <FileInput
              fieldName="idProof"
              uploadFileLinkText="Choisir un fichier"
              changeFileLinkText="Changer de fichier"
              description="Carte d'identité ou passport"
              @handleFileChange=handleFileChange
            />
          </div>

          <div class="col-span-6">
            <label
              for="rib"
              class="text-sm font-medium text-gray-700"
            >
              RIB - Optionnel<br>
              <span class="text-sm text-gray-500">Compte bancaire où recevoir l'indemnité. Vous pourrez l'ajouter plus tard.</span>
            </label>
            <FileInput
              fieldName="rib"
              uploadFileLinkText="Choisir un fichier"
              changeFileLinkText="Changer de fichier"
              description="Relevé d'identité bancaire"
              @handleFileChange=handleFileChange
            />
          </div>

        </div>
      </div>
      <div
        class="px-4 py-3 inline-flex justify-between w-full bg-gray-50 text-right sm:px-6"
      >
        <button>
          <router-link
            :to="{ name: 'FunnelWarrant' }"
            class="text-gray-700 inline-flex py-2 font-medium"
          >
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Retour
          </router-link>
        </button>
        <button
          :disabled=waitingForApiResponse
          type="submit"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <div v-if=!waitingForApiResponse>
            Suivant
          </div>
          <pulse-loader v-else color="#fff" size="6px" />
        </button>
      </div>
  </form>
  </div>
</template>

<script>
import { axios } from "@/utils";

import FileInput from "@/components/ui/FileInput.vue"
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  components: {
    FileInput,
    PulseLoader
  },
  data() {
    return {
      failedToFetchData: null,
      alreadyUploaded: false,
      waitingForApiResponse: false,
      legalDocs: {
        idProof: null,
        rib: null
      },
      notification: {
        title: 'Étape validée',
        message: 'Les pièces légales ont été enregistrées avec succès',
        isSuccess: true,
        isDisplayed: true,
        duration: 4000
      },
    };
  },
  computed: {
  },
  methods: {
    async uploadLegalDocs(e) {
      e.preventDefault();
      try {
        const formData = new FormData();
        formData.append('idProof', this.legalDocs.idProof);
        formData.append('rib', this.legalDocs.rib);

        const { slug } = this.$route.params
        this.waitingForApiResponse = true
        await axios.post(`/passengers/funnel/${slug}/upload-legal-docs`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.$router.push({ name: 'FunnelDefinePassword' })
      } catch (error) {
        console.log('>>>>> error', error)
        this.notification = {
          title: 'Failed to upload legal docs',
          message: error.response?.data?.message || error.message,
          isSuccess: false,
          duration: 6000
        }
      } finally {
        this.emitNotification()
        this.waitingForApiResponse = false
      }
    },
    handleFileChange(fieldName, fileInput) {
      this.legalDocs[fieldName] = fileInput
    },
    emitNotification() {
      this.$emit('showNotification', this.notification)
    }
  }
};
</script>

<style>
</style>